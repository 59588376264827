import { TuixTextComponent } from 'components/TuixWebcomponents'
import { Link } from 'gatsby'
import React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from 'utils/language'
import './SuccessStoryCard.scss'

interface Props {
    urlImage: string
    urlLogo?: string
    className?: string
    path: string
    locale: Locale
    companyName: string
    viewMoreText: string
}

export default ({
    urlImage,
    className,
    urlLogo,
    path,
    locale,
    companyName,
    viewMoreText,
}: Props): JSX.Element => {
    return (
        <div className={className}>
            <Link
                to={`/${getLanguageByLocale(locale)}/${path}`}
                aria-label={`Go to ${companyName} success story`}
            >
                <div
                    className="grid-item__image"
                    style={{
                        backgroundImage: `url("${urlImage}?w=430&q=85&fm=webp")`,
                    }}
                >
                    {urlLogo && (
                        <img
                            className="grid-item__logo"
                            src={urlLogo}
                            alt={`${companyName} logo`}
                        />
                    )}
                </div>
                <div className="grid-item__overlay">
                    <TuixTextComponent size={TextSize.Button} color={TextVariant.LIGHT}>
                        {viewMoreText}
                    </TuixTextComponent>
                </div>
            </Link>
        </div>
    )
}
