import clsx from 'clsx'
import SuccessStoryCard from 'components/SuccessStoryCard'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import React, { useEffect, useRef, useState } from 'react'
import { Locale } from 'utils/enums'
import { File } from 'utils/types/common.types'
import './SuccessStories.scss'

export interface SuccessStoryCardModel {
    homeImage: File
    logoHomePage?: File
    node_locale: Locale
    slug: string
    companyName: string
}

interface Props {
    successStories: readonly SuccessStoryCardModel[]
    locale: Locale
    viewMoreText: string
}

export default ({ successStories = [], locale, viewMoreText }: Props): JSX.Element => {
    const { isMobile, isTablet, isDesktop } = useDeviceWidth()
    const elementRef = useRef(null)
    const registerElement = useIntersectionObserver()
    const [enableEffects, setEnableEffects] = useState(false)

    useEffect(() => {
        registerElement(elementRef.current, true)
    }, [elementRef])

    useEffect(() => {
        const totalImages = successStories.length
        if (isTablet) {
            setEnableEffects(totalImages > 2)
        }
        if (isTablet || isMobile) {
            setEnableEffects(totalImages > 2)
        }
        if (isDesktop) {
            setEnableEffects(totalImages > 3)
        }
    }, [isTablet, isMobile, isDesktop])

    return (
        <div className="section3__success-stories">
            <div
                className={clsx(
                    'section3__success-stories__grid-container',
                    enableEffects && 'animated-grid',
                )}
                ref={elementRef}
            >
                {successStories.map((story, index) => (
                    <SuccessStoryCard
                        className={`grid-item item-${index + 1}`}
                        key={index}
                        urlImage={story.homeImage?.file.url}
                        urlLogo={story.logoHomePage?.file.url}
                        locale={locale}
                        path={`success-stories/${story.slug}`}
                        companyName={story.companyName}
                        viewMoreText={viewMoreText}
                    />
                ))}
            </div>
        </div>
    )
}
